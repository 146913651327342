import React from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { hexToRgb } from '../utils/hexToRgb';
import Arabesco from '../images/arabescos.svg';
import { PageWrapper, PageTitle } from '../components/pageComponents';
import {
  SupplierProfileDataCard,
  SupplierCreateUsers,
  SupplierPointsOutgoing,
} from '../components';

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  ${({ bottom }) =>
    bottom
      ? 'margin-top: 1em; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; '
      : 'margin-bottom: 1em;'}
`;

const SupplierProfile = () => {
  return (
    <PageWrapper>
      <PageTitle>CADASTRO DA EMPRESA</PageTitle>
      <Card>
        <AssetContainer />
        <SupplierProfileDataCard />
        <SupplierCreateUsers />
        <SupplierPointsOutgoing />
      </Card>
    </PageWrapper>
  );
};

export default SupplierProfile;
