import React, { useState, useEffect } from 'react';
import { Tabs } from '../components';
import RatingCard from '../components/RatingCard';
import { PageTitle, PageWrapper, CenteredText } from '../components/pageComponents';

// import useFetch, { method } from '../hooks/useFetch';
// import { urls } from '../services/urls';
import { bride } from '../services/api';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import { useStateValue } from '../state'

const BrideRattingPage = () => {
  const [ratingSupport, setRatingSupport] = useState(null);
  const [ratingeExecution, setRatingExecution] = useState(null);
  const [ratingPrizes, setRatingPrizes] = useState(null);
  const [
    {
      session: { selectedEvent },
    },
  ] = useStateValue();
  

  const fetchRatingSupport = async () => {
    const response = await bride.getRatingSupport(selectedEvent);

    if (response.ok && response.data) {
      setRatingSupport(response.data);
    }
  };
  const fetchRatingExecution = async () => {
    const response = await bride.getRatingExecution(selectedEvent);

    if (response.ok && response.data) {
      setRatingExecution(response.data);
    }
  };

  const fetchRatingPrize = async () => {
    const response = await bride.getRatingPrize(selectedEvent);

    if (response.ok && response.data) {
      setRatingPrizes(response.data);
    }
  };
  useEffect(() => {
    if (selectedEvent) {
      fetchRatingSupport();
      fetchRatingExecution();
      fetchRatingPrize();
    }
  // eslint-disable-next-line
  },[selectedEvent]);
  // const { doUpdate: updateSupport } = useFetch({
  //   url: urls.bridesContent.rattingSupport(selectedEvent),
  //   method: method.GET,
  //   condition: () => !ratingSupport,
  //   callback: data => setRatingSupport(data),
  // });

  // const { doUpdate: updateExecution } = useFetch({
  //   url: urls.bridesContent.ratingeExecution(selectedEvent),
  //   method: method.GET,
  //   condition: () => !ratingeExecution,
  //   callback: data => setRatingeExecution(data),
  // });

  // const { doUpdate: updatePrize } = useFetch({
  //   url: urls.bridesContent.ratingePrize(selectedEvent),
  //   method: method.GET,
  //   condition: () => !ratingPrizes,
  //   callback: data => setRatingPrizes(data),
  // });

  const tabsContent = [
    {
      id: 0,
      title: 'ATENDIMENTO',
    },
    {
      id: 1,
      title: 'EXECUÇÃO',
    },
    {
      id: 2,
      title: 'PRÊMIOS',
    },
  ];

  const handleRattingCards = () => {
    if (!ratingSupport || !ratingeExecution || !ratingPrizes) {
      return <Loader />;
    }
  };

  const rateSupport = async (cod, NPS, obs, reason) => {
    const payload = {
      cod,
      NPS,
      obs,
      reason,
    };
    const response = await bride.rateSupport(payload);
    if (response.ok) {
      setRatingSupport(null);
      fetchRatingSupport();
    } else {
      toast.error('Erro ao avaliar');
    }
  };

  const rateExecution = async (cod, NPS, obs) => {
    const payload = {
      cod,
      NPS,
      obs,
    };
    const response = await bride.rateExecution(payload);
    if (response.ok) {
      setRatingExecution(null);
      fetchRatingExecution();
    } else {
      toast.error('Erro ao avaliar');
    }
  };

  const ratePrize = async (cod, NPS, obs) => {
    const payload = {
      cod,
      NPS,
      obs,
    };
    const response = await bride.ratePrize(payload);
    if (response.ok) {
      setRatingPrizes(null);
      fetchRatingPrize();
    } else {
      toast.error('Erro ao avaliar');
    }
  };

  const RattingSupport = () =>
    ratingSupport && ratingSupport.length > 0
    ? ratingSupport.map(support => (
      <RatingCard
        key={support.id}
        ratingData={support}
        type={0}
        rateHandler={rateSupport}
      />
    )) : <CenteredText>Nenhum atendimento para avaliar</CenteredText> 

  const RattingExecution = () =>
    ratingeExecution && ratingeExecution.length > 0
    ? ratingeExecution.map(execution => (
      <RatingCard
        key={execution.id}
        ratingData={execution}
        rateHandler={rateExecution}
        type={1}
      />
    )) : <CenteredText>Nenhuma execução para avaliar</CenteredText> 

  const RattingPrizes = () =>
    ratingPrizes && ratingPrizes.length > 0
    ? ratingPrizes.map(prize => (
        <RatingCard
          key={prize.id}
          ratingData={prize}
          type={3}
          rateHandler={ratePrize}
        />
      )) 
    : <CenteredText>Nenhum prêmio para avaliar</CenteredText> 

  const components = [
    <RattingSupport key={0} />,
    <RattingExecution key={1} />,
    <RattingPrizes key={2} />,
  ];

  return (
    <PageWrapper>
      <PageTitle>AVALIAÇÃO</PageTitle>
      {ratingSupport && ratingeExecution && ratingPrizes ? (
        <Tabs
          content={tabsContent}
          onClick={() => {}}
          components={components}
        />
      ) : (
        handleRattingCards()
      )}
    </PageWrapper>
  );
};

export default BrideRattingPage;
