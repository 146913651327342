import React, { useState } from 'react';
import { PageTitle, PageWrapper } from '../components/pageComponents';

import { BrideReferForm } from '../containers';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { useStateValue } from '../state';

const BrideReferPage = () => {
  const [checkEvent, setCheckEvent] = useState(null);

  const [
    {
      session: { selectedEvent },
    },
  ] = useStateValue();

  useFetch({
    url: urls.bridesContent.checkIndication(selectedEvent),
    method: method.GET,
    condition: () => !checkEvent,
    callback: data => (data ? setCheckEvent(true) : setCheckEvent(false)),
  });

  return (
    <PageWrapper>
      <PageTitle>INDICAR UMA PESSOA</PageTitle>
      {checkEvent ? <BrideReferForm /> : <p>Você ja indicou uma pessoa</p>}
    </PageWrapper>
  );
};

export default BrideReferPage;
