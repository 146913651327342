import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';


import {
  SupplierReportCard,
  Step,
  Loader,
  BlogPost,
  CardContainer,
  DropdownContracts,
} from '../components';
import { Rings, Like, Calculator, SearchIcon } from '../components/icons';
import { useStateValue } from '../state';
import { medias } from '../utils/mediaQueries';
import theme from '../styles/theme';
import { urls } from '../services/urls';
import useFetch, { method } from '../hooks/useFetch';
import SupplierRewardCard from '../components/SupplierRewardCard';
import { supplier } from '../services/api';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CardWrapper = styled.div`
  max-width: 600px;
  width: 50%;
  align-items: center;

  @media ${medias.laptopMax} {
    width: 90%;
  }
`;

const RewardsWrapper = styled.div`
  align-items: center;
  width: 90%;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;

  @media ${medias.laptopMax} {
    flex-direction: column;
    align-items: center;
  }
`;

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;

  @media ${medias.laptopMax} {
    width: 70%;
  }

  @media ${medias.phoneMax} {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  height: 120px;
  width: 120px;
`;

const TextNoRewards = styled.p`
  display: flex;
  margin-left: 20%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const RowContainer = styled.div`
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ImagesRowContainer = styled.div`
  @media ${medias.laptopMax} {
    font-size: ${theme.fontSizeSm}px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
`;

const SupplierDescription = styled.p`
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
  }
  width: 300px;
  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.grey2};
  text-align: left;
  font-weight: 300;
  margin: 0;

  strong {
    color: ${theme.colors.primary};
    font-weight: 300;

    b {
      font-weight: bold;
    }
  }
`;

const CardTitle = styled.h3`
  color: ${theme.colors.grey3};
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: ${theme.colors.primary};
`;

const BudgetIcon = () => (
  <IconContainer>
    <Calculator />
  </IconContainer>
);

const StyledLink = styled.a`
  color: ${theme.colors.text};
  font-size: ${theme.fontSize}px;
`;

const SupplierHome = () => {
  const [advantages, setAdvantages] = useState(null);
  const [opportunities, setOpportunities] = useState(null);
  const [availableBrides, setAvailableBrides] = useState(null);
  const [rewards, setRewards] = useState(null);
  const [indicationsAmount, setIndicationsAmount] = useState(null);
  const [blogPosts, setBlogPosts] = useState(null);
  const [supplierData, setSupplierData] = useState(null);
  const [filteredReport, setFilteredReport] = useState(null);
  const [currContractInfo, setCurrContractInfo] = useState(false);
  const [contractInfoData, setContractInfoData] = useState(null);
  const [
    {
      session: {
        supplierName,
        vbs,
        potential,
        contractsCount,
        totalContractsValue,
        roi,
        pointsBalance,
        supplierCategory
      },
    },
  ] = useStateValue();

  useEffect(() => {
    setSupplierData({
      vbs,
      potential,
      contracts: contractsCount,
      deals: totalContractsValue,
      roi,
      points: pointsBalance,
    });
  }, [contractsCount, pointsBalance, potential, roi, totalContractsValue, vbs]);

  useEffect(() => {
    fetchBlogPosts();
  }, [fetchBlogPosts]);

  const fetchBlogPosts = useCallback(async () => {
    await fetch(
      'https://blog.vestidasdebranco.com.br/wp-json/wp/v2/posts?tags=6'
    ).then(response => response.json().then(data => handleBlogPostsData(data)));
  }, []);

  const selectContract = async item => {
    if (item == 'all') setFilteredReport(false);
    const num = Number(item.codContratoFornecedor); //ensure it's the correct format
    const response = await supplier.filterReports(num);
    if (response.ok && response.data) {
      const {
        vbs,
        potential,
        contractsCount,
        totalContractsValue,
        roi,
        pointsBalance,
      } = response.data;
      setFilteredReport({
        vbs,
        potential,
        contracts: contractsCount,
        deals: totalContractsValue,
        roi,
        points: pointsBalance,
      });
    }
    showContractInfo(item);
  };

  useFetch({
    url: urls.supplierContent.advantagesConsolidation,
    method: method.GET,
    condition: () => !advantages,
    callback: ({ totalAdvantages }) => setAdvantages(totalAdvantages),
  });

  useFetch({
    url: urls.supplierContent.advantagesConsolidation,
    method: method.GET,
    condition: () => !advantages,
    callback: ({ activeAdvantages }) => setAdvantages(activeAdvantages),
  });

  useFetch({
    url: urls.supplierContent.opportunitiesConsolidation(),
    method: method.GET,
    condition: () => !opportunities,
    callback: ({ total }) => setOpportunities(total),
  });

  useFetch({
    url: urls.supplierContent.avaliableBridesConsolidation(),
    method: method.GET,
    condition: () => !availableBrides,
    callback: data => {
      setAvailableBrides(data.count);
    },
  });

  useFetch({
    url: urls.supplierContent.getRewards,
    method: method.GET,
    condition: () => !rewards,
    callback: data => setRewards(data),
  });

  useFetch({
    url: urls.supplierContent.supplierIndicationAmount,
    method: method.GET,
    condition: () => !indicationsAmount,
    callback: data => {
      data === undefined
        ? setIndicationsAmount(undefined)
        : setIndicationsAmount(data.indicationsAmount);
    },
  });

  const handleBlogPostsData = data => {
    const mappedData = data.map(post => ({
      id: post.id,
      title: post.title.rendered,
      description: post.excerpt.rendered,
      link: post.link,
      imageCode: post.featured_media,
    }));

    setBlogPosts(mappedData);
  };


  const showContractInfo = (contract) => {
    if (contract) {
      setContractInfoData(contract.codCategoriaContratoFornecedor.CategoriaContratoFornecedor);
      setCurrContractInfo(true);
    }
  }
  
  useEffect(() => {
    const setCategories = {
      1: () => setContractInfoData('BRONZE'),
      2: () => setContractInfoData('PRATA'),
      3: () => setContractInfoData('OURO'),
      4: () => {}
    }
    setCategories[supplierCategory]()
    setCurrContractInfo(true);
  }, [supplierCategory]);
  

  return (
    <Wrapper>
      {supplierData && rewards && supplierName ? (
        <>
          <ContentWrapper>
            <StepsWrapper>
              {Number(supplierCategory) !== 4 && (
                <Step
                  icon={
                    <ImageContainer>
                      <Like />
                    </ImageContainer>
                  }
                  title="Passo 1"
                  description="Cadastre seus benefícios"
                  supplierDescription={
                    <SupplierDescription>
                      Você tem:{' '}
                      <strong>
                        <b>{advantages}</b> Benefícios
                      </strong>
                    </SupplierDescription>
                  }
                  path="vantagens"
                />
              )}

              <Step
                icon={
                  <IconContainer>
                    <SearchIcon viewBox="-15 -15 50 50" />
                  </IconContainer>
                }
                title={Number(supplierCategory) === 4 ? 'Passo 1' : 'Passo 2'}
                description="Insira Seus Noivos"
                supplierDescription={
                  <SupplierDescription>
                    Você inseriu:{' '}
                    <strong>
                      <b>{indicationsAmount}</b> Noivos
                    </strong>
                  </SupplierDescription>
                }
                path="convidar"
              />

              {Number(supplierCategory) !== 4 && (
                <>
                  <Step
                    icon={<BudgetIcon />}
                    title="Passo 3"
                    description="Gerencie seus orçamentos"
                    supplierDescription={
                      <SupplierDescription>
                        Você tem:{' '}
                        <strong>
                          <b>{opportunities}</b> orçamentos
                        </strong>
                      </SupplierDescription>
                    }
                    path="oportunidades"
                  />

                  <Step
                    icon={
                      <ImageContainer>
                        <Rings />
                      </ImageContainer>
                    }
                    title="Passo 4"
                    description="Pesquise noivos disponíveis"
                    supplierDescription={
                      <SupplierDescription>
                        Você tem:{' '}
                        <strong>
                          {/* <b>{availableBrides}</b>  */}- Noivos disponíveis
                        </strong>
                      </SupplierDescription>
                    }
                    path="noivos"
                  />

                  <Step
                    icon={
                      <ImageContainer>
                        <Rings />
                      </ImageContainer>
                    }
                    title="Passo 5"
                    description="Pontuar noivos"
                    path="fornecedor/noivos"
                  />
                </>
              )}
            </StepsWrapper>
            <CardWrapper>
              <CardTitle>Seus números</CardTitle>
              <RowContainer>
                <DropdownContracts
                  onSelectItem={item => selectContract(item)}
                  placeholder={'Filtrar por contrato'}
                />
                </RowContainer>
                <RowContainer>{currContractInfo && contractInfoData && (
                <SupplierDescription>Categoria: <strong>{contractInfoData}</strong>{'  '}
                <StyledLink href="https://storage.googleapis.com/assets-vdb/contrato/convenio.pdf" rel="noopener noreferrer" target="_blank">(abrir contrato)</StyledLink>
                </SupplierDescription>)}
              </RowContainer>
              {filteredReport ? (
                <SupplierReportCard supplierData={filteredReport} />
              ) : supplierData ? (
                <SupplierReportCard supplierData={supplierData} />
              ) : null}
            </CardWrapper>
          </ContentWrapper>
          <CardTitle>Recompensas - Use suas moedas</CardTitle>
          <RewardsWrapper>
            <CardContainer>
              <RowContainer>
                {rewards.length > 0 ? (
                  rewards.slice(0, 2).map((item, index) => (
                    <div style={{ width: '47%' }} key={index.toString()}>
                      <SupplierRewardCard balance={vbs} {...item} />
                    </div>
                  ))
                ) : (
                  <TextNoRewards>Nenhuma recompensa</TextNoRewards>
                )}
              </RowContainer>
            </CardContainer>
          </RewardsWrapper>
        </>
      ) : (
        <Loader />
      )}
      <ImagesRowContainer padding>
        {blogPosts &&
          blogPosts.map((item, index) => (
            <BlogPost key={index.toString()} data={item} />
          ))}
      </ImagesRowContainer>
    </Wrapper>
  );
};

export default SupplierHome;
