import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import {
  SearchBar,
  Tabs,
  Loader,
  Pagination2,
  SmallAdvantagesCard,
} from '../components';
import theme from '../styles/theme';
import { useStateValue } from '../state';
import { bride, tracking } from '../services/api';
import { Redirect } from '@reach/router';
import {
  PageTitle,
  PageWrapper,
  CenteredText,
} from '../components/pageComponents';
import { scrollToRef } from '../hooks';
import { medias } from '../utils/mediaQueries';

const StyledSearchbar = styled(props => <SearchBar {...props} />)`
  background-color: ${theme.colors.text_heading};
  width: 100%;
`;

const StyledDiv = styled.div`
  width: 100%;
`;

const GridContainer = styled.div`
  @media ${medias.phoneMax} {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }

  display: grid;
  margin-top: 20px;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 10px;
`;

const BrideAdvantagesPages = () => {
  const [searchFilter, setSearchFilter] = React.useState(null);
  const [segmentFilter, setSegmentFilter] = React.useState(null);
  const [filter, setFilter] = React.useState(null);
  const [initialPage, setInitialPage] = React.useState(1);
  const [activeTab, setActiveTab] = useState(0);
  const [advantagesTotal, setAdvantagesTotal] = useState([]);
  const [advantages, setAdvantages] = useState(null);
  const [featuredAdvantages, setFeaturedAdvantages] = useState(null);
  const [searched, setSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [advantagesFavorites, setAdvantagesFavorites] = useState(null);
  const [
    {
      session: {
        userId,
        category,
        eventFinished,
        selectedEvent,
        selectedRegion,
      },
    },
  ] = useStateValue();

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const resPerPage = 10;
  const fetchAdvantages = async pageNumber => {
    setInitialPage(pageNumber);

    if (!filter) {
      const response = await bride.getAdvantages(
        selectedEvent,
        selectedRegion,
        resPerPage,
        pageNumber
      );

      if (response.ok && response.data) {
        setAdvantagesTotal(response.data.total);
        setAdvantages(response.data.filteredAdvantages);
        setIsLoading(false);
      }
    }

    if (filter === 'keyword,segment') {
      const response = await bride.getAdvantagesFilter(
        selectedEvent,
        selectedRegion,
        searchFilter,
        segmentFilter,
        resPerPage,
        pageNumber
      );

      if (response.ok && response.data) {
        setAdvantages(response.data.filteredAdvantages);
        setAdvantagesTotal(response.data.total);
        setSearched(true);
        setIsLoading(false);
        return;
      }
    }

    if (filter === 'segment') {
      const response = await bride.getAdvantagesByValue(
        selectedEvent,
        selectedRegion,
        segmentFilter,
        resPerPage,
        pageNumber
      );

      if (response.ok && response.data) {
        setAdvantages(response.data.filteredAdvantages);
        setAdvantagesTotal(response.data.total);
        setSearched(true);
        setIsLoading(false);
        return;
      }
    }

    if (filter === 'keyword') {
      const response = await bride.getAdvantagesByKeyword(
        selectedEvent,
        selectedRegion,
        searchFilter,
        resPerPage,
        pageNumber
      );

      if (response.ok && response.data) {
        setAdvantages(response.data.filteredAdvantages);
        setAdvantagesTotal(response.data.total);
        setSearched(true);
        setIsLoading(false);
        return;
      }
    }
  };

  useEffect(() => {
    const fetchFavoritesAdvantages = async () => {
      const response = await bride.favoritesAdvantages(selectedEvent);

      if (response.ok && response.data) {
        setAdvantagesFavorites(response.data);
      }
    };

    if (selectedEvent) {
      fetchAdvantages(1);
      fetchFavoritesAdvantages();
    }
    // eslint-disable-next-line
  }, [selectedEvent]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await bride.featuredAdvantages();

      if (response.ok && response.data) {
        setFeaturedAdvantages(response.data);
      }
    };

    fetchData();
  }, []);

  const removeFavorite = async cod => {
    const oldAdvantages = advantages;
    const oldFavorites = advantagesFavorites;

    const newAdvantages = advantages.map(adv => {
      if (adv.codAdvantage === cod) {
        adv = { ...adv, isFavorited: false };
      }
      return adv;
    });
    const newFav = advantagesFavorites.filter(adv => adv.codAdvantage !== cod);

    setAdvantages(newAdvantages);
    setAdvantagesFavorites(newFav);

    const response = await bride.removeFavorite(selectedEvent, 2, cod);
    if (!response.ok) {
      setAdvantages(oldAdvantages);
      setAdvantagesFavorites(oldFavorites);
    }
  };

  const addFavorite = async cod => {
    const oldAdvantages = advantages;
    const oldFavorites = advantagesFavorites;

    const newAdvantages = advantages.map(adv => {
      if (adv.codAdvantage === cod) {
        adv = { ...adv, isFavorited: true };
      }
      return adv;
    });
    const advFav = newAdvantages.filter(adv => adv.codAdvantage === cod);
    const newFavorites = advantagesFavorites.concat(advFav);

    setAdvantages(newAdvantages);
    setAdvantagesFavorites(newFavorites);

    const payload = {
      codEvent: selectedEvent,
      codRegion: selectedRegion,
      codAdvantage: cod,
    };

    const response = await bride.addFavorite(payload);
    if (!response.ok) {
      setAdvantages(oldAdvantages);
      setAdvantagesFavorites(oldFavorites);
    }
  };

  const handleSearch = async (input, dropdown) => {
    if (!input && !dropdown) {
      return
    }
    
    const { codSegmento } = dropdown;
    setIsLoading(true);
    setInitialPage(1);
    setActiveTab(1);

    tracking.search({
      userId,
      eventId: selectedEvent,
      input,
      module: 'beneficio',
    });

    if (input && dropdown) {
      setFilter('keyword,segment');
      setSegmentFilter(codSegmento);
      setSearchFilter(input);
      const response = await bride.getAdvantagesFilter(
        selectedEvent,
        selectedRegion,
        input,
        codSegmento,
        resPerPage
      );

      if (response.ok && response.data) {
        setAdvantages(response.data.filteredAdvantages);
        setAdvantagesTotal(response.data.total);
        setSearched(true);
        setIsLoading(false);
        return;
      }
    }

    if (!input && dropdown) {
      setFilter('segment');
      setSegmentFilter(codSegmento);
      const response = await bride.getAdvantagesByValue(
        selectedEvent,
        selectedRegion,
        codSegmento,
        resPerPage
      );

      if (response.ok && response.data) {
        setAdvantages(response.data.filteredAdvantages);
        setAdvantagesTotal(response.data.total);
        setSearched(true);
        setIsLoading(false);
        return;
      }
    }

    if (input && !dropdown) {
      setFilter('keyword');
      setSearchFilter(input);
      const response = await bride.getAdvantagesByKeyword(
        selectedEvent,
        selectedRegion,
        input,
        resPerPage
      );

      if (response.ok && response.data) {
        setAdvantages(response.data.filteredAdvantages);
        setAdvantagesTotal(response.data.total);
        setSearched(true);
        setIsLoading(false);
        return;
      }
    }
  };

  const handleClear = async () => {
    setIsLoading(true);
    setInitialPage(1);
    setFilter(null);
    setSegmentFilter(null);
    setSearchFilter(null);

    const response = await bride.getAdvantages(
      selectedEvent,
      selectedRegion,
      resPerPage
    );

    if (response.ok && response.data) {
      setAdvantagesTotal(response.data.total);
      setAdvantages(response.data.filteredAdvantages);
      setSearched(false);
      setIsLoading(false);
      return;
    }
  };

  const onPageChange = pageNumber => {
    setIsLoading(true);
    fetchAdvantages(pageNumber);
  };

  const tabsContent = [
    {
      id: 0,
      title: 'DESTAQUE',
    },
    {
      id: 1,
      title: 'TODOS',
    },
    {
      id: 2,
      title: 'FAVORITOS',
    },
  ];

  const AdvantagesCards = () =>
    advantages.length ? (
      <GridContainer>
        {advantages.map(advantage => (
          <SmallAdvantagesCard
            key={advantage.codAdvantage}
            advantageData={advantage}
            category={category}
            removeFavorite={removeFavorite}
            addFavorite={addFavorite}
          />
        ))}
      </GridContainer>
    ) : (
      <CenteredText>Nenhum benefício disponível</CenteredText>
    );

  const FeaturedCards = () =>
    featuredAdvantages.length ? (
      <GridContainer>
        {featuredAdvantages.map(advantage => (
          <SmallAdvantagesCard
            key={advantage.codAdvantage}
            advantageData={advantage}
            category={category}
            removeFavorite={removeFavorite}
            addFavorite={addFavorite}
          />
        ))}
      </GridContainer>
    ) : (
      <CenteredText>Nenhum benefício disponível</CenteredText>
    );

  const AdvantagesFavorites = () =>
    advantagesFavorites.length ? (
      <GridContainer>
        {advantagesFavorites.map(advantage => (
          <SmallAdvantagesCard
            key={advantage.codAdvantage}
            advantageData={advantage}
            category={category}
            removeFavorite={removeFavorite}
            addFavorite={addFavorite}
          />
        ))}
      </GridContainer>
    ) : (
      <CenteredText>Nenhum benefício favoritado</CenteredText>
    );

  const components = [
    <FeaturedCards key={0} />,
    <AdvantagesCards key={1} />,
    <AdvantagesFavorites key={2} />,
  ];

  if (eventFinished) {
    return <Redirect to="/app/noivos/inicio" />;
  }

  return (
    <PageWrapper>
      <PageTitle>BENEFÍCIOS</PageTitle>
      <StyledDiv ref={myRef}>
        <StyledSearchbar
          advantages
          searched={searched}
          onClickClear={handleClear}
          onClickSearch={handleSearch}
          placeholder="Palavra-chave?"
        />
      </StyledDiv>
      {advantages &&
      advantagesFavorites &&
      eventFinished === false &&
      !isLoading ? (
        <>
          <Tabs
            content={tabsContent}
            components={components}
            activeTab={activeTab}
            onClick={tab => setActiveTab(tab)}
          />
          <div>
            {activeTab === 0 && (
              <Pagination2
                items={advantages}
                itemsTotal={advantagesTotal}
                onChangePage={onPageChange}
                pageSize={resPerPage}
                scrollToRef={executeScroll}
                initialPage={initialPage}
              />
            )}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </PageWrapper>
  );
};

export default BrideAdvantagesPages;
