import React, { useState } from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import { toast } from 'react-toastify';

import ModalContainer, { ModalTitle } from './ModalContainer';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { MaterialInput } from '.';
import {
  MaterialInputLabel,
  MaterialSelect,
  MaterialMenuItem,
  MoneyMask,
} from '../components';
import { supplier } from '../services/api';
import dateUndefined from '../utils/undefinedDate';
import { UserIcon } from './icons';
import { ListDivisor } from './styled';


const Wrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto;
  grid-gap: 1em 0.25em;
  border: 0.2px solid ${theme.colors.text_heading};
  border-radius: 0.2em;
  padding: 0.5rem 0 0;
  margin-bottom: 1.5rem;
  align-items: center;
  svg {
    max-width: 1.5em;
    max-height: 1.5em;
    fill: ${theme.colors.primary};
  }
  .button-page {
    background: ${theme.colors.secondary};
    color: white;
    padding: 0.5em 1em;
    border-radius: 0.25em;
  }
  h2 {
    font-weight: bold;
    font-size: 1em;
    margin: 0;
  }
  .contract-success {
    text-align: center;
    font-weight: bold;
  }
  .contract-form {
    position: relative;
    padding: 0 0 2.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn_exit {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: transparent;
      width: auto;
      color: ${theme.colors.grey3};
      font-size: 0.85em;
    }
  .mark-button {
     display: flex;
     align-items: center;
     text-align: center;
     background: ${theme.colors.text_heading};
     align-content: center;
     color: white;
     padding: 0.5em 0.5em;
     border-radius: 0.25em;
    }
    .marked-button {
     display: flex;
     align-self: center;
     text-align: center;
     background: ${theme.colors.text};
     align-content: center;
     color: white;
     padding: 0.5em 0.5em;
     border-radius: 0.25em;
    }
 
  @media ${medias.phoneMin} {
    grid-template-columns: auto auto;
    button,
    .contract-form,
    .contract-success {
      /* Um valor alto para o botão estar sempre ao final */
      grid-area: 4 / 1 / 4 / -1;
    }
  }
  @media ${medias.tabletMin} {
    grid-gap: 1em 0.5em;
    grid-area: 3 / 1 / 3 / -1;
    grid-template-columns: repeat(3, auto);
  }
`;

const StyledSpanFontSm = styled.span`
  font-size: ${theme.fontSizeSm} - 1px;
  @media ${medias.phoneMax} {
    text-align: center;
  }
`;

const StyledSpan = styled.span`
  padding-left: 0.5rem;
  @media ${medias.phoneMax} {
    text-align: center;
  }
`;

const StyledH2 = styled.h2`
  @media ${medias.phoneMax} {
    text-align: center;
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledFormControl = styled(FormControl)`
  width: 50%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;


const GenerateContractModal = ({
  onCloseModal,
  opened,
  segmentData,
  regionsData,
  clientEmail,
  updateOportunities,
  brideId,
}) => {
  const [value, setValue] = useState('');
  const [segment, setSegment] = useState('');
  const [region, setRegion] = useState('');
  const [sendActionCount, setSendActionCount] = useState(0);


  const handleSubmit = async () => {
    if (!region || !segment || !value) {
      toast.error('Preencha todos os campos!');
      return;
    }
    if (sendActionCount === 0) {
      const val = value.split('.');
      const verif = await supplier.checkContractValue(val[0], val[1], brideId);
      if (!verif.ok) {
        return toast.error('Falha ao verificar contratos.');
      }
      // eslint-disable-next-line no-const-assign
      setSendActionCount(sendActionCount++);
      if (verif.data.contractAmount) {
        return toast.info(
          'Existe um contrato registrado com mesmo valor e cliente. Assegure-se de que deseja continuar.',
          { autoClose: false }
        );

      }
    }
    if (sendActionCount !== 0) {
      const payload = {
        value,
        codRegion: region,
        codSegment: segment,
        email: clientEmail,
      };


      const res = await supplier.inviteBrides(payload);
      if (!res.ok) {
        return toast.error('Falha ao gerar contrato.');
      }
      setValue('');
      setSegment('');
      setRegion('');
      onCloseModal();
      updateOportunities();
      return toast.success('Contrato gerado com sucesso!', { autoClose: 9500 });
    }
  };

  return (
    <ModalContainer opened={opened} closeModal={onCloseModal} hasArabesco>
      <ModalWrapper>
        <ModalTitle>REGISTRAR CONTRATO</ModalTitle>

        <MaterialInput
          style={{ width: '100%' }}
          label="Valor do contrato"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          InputProps={{
            inputComponent: MoneyMask,
          }}
        />
        <Row>
          <StyledFormControl style={{ width: '45%' }}>
            <MaterialInputLabel htmlFor="region">Região</MaterialInputLabel>
            <MaterialSelect
              onChange={event => setRegion(event.target.value)}
              value={region}
              inputProps={{
                name: 'region',
                id: 'region',
              }}
            >
              {regionsData &&
                regionsData.map(({ region, codRegion }) => (
                  <MaterialMenuItem value={codRegion} key={codRegion}>
                    {region}
                  </MaterialMenuItem>
                ))}
            </MaterialSelect>
          </StyledFormControl>

          <StyledFormControl>
            <MaterialInputLabel htmlFor="segment">Segmento</MaterialInputLabel>
            <MaterialSelect
              onChange={event => setSegment(event.target.value)}
              value={segment}
              inputProps={{
                name: 'segment',
                id: 'segment',
              }}
            >
              {segmentData &&
                segmentData.map(({ segment, codSegment }) => (
                  <MaterialMenuItem value={codSegment} key={codSegment}>
                    {segment}
                  </MaterialMenuItem>
                ))}
            </MaterialSelect>
          </StyledFormControl>
        </Row>
        <button
          onClick={handleSubmit}
          className="button-page"
          style={{ width: '50%', marginTop: '1em' }}
        >
          REGISTRAR
        </button>
      </ModalWrapper>
    </ModalContainer>
  );
};

const SupplierBridesCard = ({
  cardData,
  type,
  segmentData,
  regionsData,
  updateOportunities,
  MarkClick,
  UnmarkClick,
  contract = true,
  //checkIfContacted
}) => {
  const {
    clientLogged,
    eventDate,
    clientPhone,
    clientName,
    clientEmail,
    clientCellphone,
    invitedNumber,
    receptionLocal,
    cerimonyLocal,
    nomeEvento,
    //eventId
  } = cardData;
  const [modalOpened, setModalOpen] = useState(false);
  const [markContacted, setMarkContacted] = useState(false);
  const [unmarked, setUnmarked] = useState(false);

  const clientFirstName = clientName.length > 0 ? clientName.split(' ')[0] : '';
  const handleMarkClick = () => {
    if (type === 0) {
      MarkClick();
      setMarkContacted(true);
      toast.success(`${clientFirstName} lembrado em Contatados`);
    }
  }

  const handleUnmarkClick = () => {
    if (type === 2) {
      UnmarkClick();
      setUnmarked(true);
      toast.success(`${clientFirstName} desmarcado de Contatados`);
    }
  }
  if (type === 2 && unmarked === true) {
    return (<Wrapper></Wrapper>);
  }

  else {

    return (
      <>
      <Wrapper>
        <StyledH2>Evento: {nomeEvento}</StyledH2>
        <StyledSpan>Data: {eventDate === dateUndefined
          ? 'indefinida'
          : moment(eventDate).format('DD/MM/YYYY')
        }</StyledSpan>
        <StyledSpan>{clientName}</StyledSpan>
        <GenerateContractModal
          onCloseModal={() => setModalOpen(false)}
          opened={modalOpened}
          segmentData={segmentData}
          regionsData={regionsData}
          clientEmail={clientEmail}
          updateOportunities={updateOportunities}
          brideId={cardData.id}
        />
        <StyledSpan><UserIcon style={{ fontWeight: 'bold' }} />{clientEmail}</StyledSpan>
        <StyledSpanFontSm>Nº: {clientCellphone}{clientPhone ? ` / ${clientPhone}` : ''}</StyledSpanFontSm>
        {type === 1 && (
          <StyledSpan>Completou cadastro e fez primeiro login? {clientLogged === 1 ? 'Sim' : 'Não'}</StyledSpan>
        )}
        {type === 0 && (
          <StyledSpan>{markContacted ?
            (<button className="marked-button">CONTATADO
           </button>) :
            (<button className="mark-button" onClick={handleMarkClick}>Já contatei {clientFirstName}
            </button>)} </StyledSpan>
        )}
        {type === 2 && (
          <StyledSpan>
            <button className="mark-button" onClick={handleUnmarkClick}>Desmarcar {clientName.length > 0 ? clientName.split(' ')[0] : ''}
            </button> </StyledSpan>
        )}
        {type === 3 && (
          <img src="https://storage.googleapis.com/assets-vdb/aliancas.svg" />
        )}

        <StyledSpan>{invitedNumber ? `${invitedNumber} convidados` : "Sem convidados definidos"}</StyledSpan>
        <StyledSpan>Cerimonia: {cerimonyLocal}</StyledSpan>
        <StyledSpan>Recepção: {receptionLocal}</StyledSpan>
        {(type === 0 && contract) ||
          (type === 3 && contract)
          && (
            <button onClick={() => setModalOpen(true)} className="button-page">
              GERAR CONTRATO
        </button>
          )}
      </Wrapper>
      {type === 0 && !contract
          && (
            <ListDivisor/>
       )}
      </>
    );

  }
};

export default SupplierBridesCard;
