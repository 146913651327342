import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Redirect } from '@reach/router';

import { PageTitle, PageWrapper } from '../components/pageComponents';
import {
  Tabs,
  SegmentList,
  DataBalance,
  Checklist,
  Loader,
} from '../components';
import { medias } from '../utils/mediaQueries';
import theme from '../styles/theme';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { bride, tracking } from '../services/api';
import { AssetContainer } from '../components/styled';
import { useStateValue } from '../state';

const tabsContent = [
  {
    id: 0,
    title: 'TODOS',
  },
  {
    id: 1,
    title: 'REDE',
  },
  {
    id: 2,
    title: 'FORA DA REDE',
  },
];

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SegmentsTitle = styled.h4`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }

  font-size: ${theme.fontSize * 1.06}px;
  font-weight: bold;
  color: ${theme.colors.text_heading};
  margin: 1em 0;
`;

const ChecklistPage = () => {
  const [avaliableSegments, setAvaliableSegments] = useState(null);
  const [selectedSegments, setSelectedSegments] = useState(null);
  const [filtered, setFiltered] = useState(false);

  const [
    {
      session: { userId, eventFinished, selectedEvent },
    },
  ] = useStateValue();

  useEffect(() => {
    const fetchData = async () => {
      const response = await bride.checklistSegments(selectedEvent);

      if (response.ok && response.data) {
        setSelectedSegments(response.data);
      }
    };

    if (selectedEvent && selectedSegments === null) {
      fetchData();
    }
  }, [selectedEvent, selectedSegments]);

  const { doUpdate: avaliableSegmentsUpdate } = useFetch({
    url: urls.siteContent.segments,
    method: method.GET,
    condition: () => !avaliableSegments,
    callback: data => setAvaliableSegments(data),
  });

  const updatePage = () => {
    setSelectedSegments(null);
    avaliableSegmentsUpdate();
  };
  const handleSegments = useCallback(() => {
    let filteredSegments = avaliableSegments;

    selectedSegments.map(({ codSegmento: { codSegmento } }) => {
      const index = avaliableSegments.findIndex(
        ({ codSegmento: id }) => id === codSegmento
      );
      filteredSegments[index] = {
        ...filteredSegments[index],
        escolhido: true,
      };
    });

    setAvaliableSegments(filteredSegments);
    setFiltered(true);
  }, [avaliableSegments, selectedSegments]);

  useEffect(() => {
    selectedSegments && avaliableSegments && handleSegments();
  }, [selectedSegments, avaliableSegments, handleSegments]);

  const calculateTotal = () => {
    const finishedSegments = selectedSegments.filter(
      item => item.finalizado === 1
    );
    let total = 0;
    selectedSegments &&
      finishedSegments.map(({ valorFechado }) => {
        total += valorFechado;
      });
    return total;
  };

  const handleChecklistItems = async item => {
    const segmentExists = selectedSegments.some(
      ({ codSegmento: { codSegmento } }) => codSegmento === item.codSegmento
    );

    tracking.segment({
      userId,
      eventId: selectedEvent,
      segmentId: item.codSegmento,
    });

    if (!segmentExists) {
      const newChecklistItem = {
        codCheckList: null,
        valorOrcado: null,
        valorFechado: null,
        dataHoraInserido: null,
        Fornecedor: null,
        prioritario: 0,
        finalizado: 0,
        vdb: 2,
        dataHoraFinalizado: null,
        codEvento: selectedEvent,
        codSegmento: item,
      };

      const oldSelectedSegments = selectedSegments;
      const newSelectedSegments = [...selectedSegments, newChecklistItem];

      setSelectedSegments(newSelectedSegments);

      window.scrollBy({
        top: -(window.innerHeight * 2.5),
        behavior: 'smooth',
      });

      const body = {
        newSegment: item,
      };

      const response = await bride.addSegmentChecklist(body);

      if (!response.ok && response.data) {
        toast.error('Erro ao adicionar o segmento!');
        setSelectedSegments(oldSelectedSegments);
        return;
      }

      newChecklistItem.codCheckList = response.data.id;
      const finalSelectedSegments = [...selectedSegments, newChecklistItem];

      setSelectedSegments(finalSelectedSegments);
      updatePage();
    }
  };

  const renderSegments = () => {
    const initialSegments = avaliableSegments.filter(
      segment => segment.faseSegmento === 1
    );
    const mediumSegments = avaliableSegments.filter(
      segment => segment.faseSegmento === 2
    );
    const finalSegments = avaliableSegments.filter(
      segment => segment.faseSegmento === 3
    );

    return (
      <>
        <SegmentList
          title="Fase Inicial"
          items={initialSegments}
          include
          wrapperSize={'90%'}
          onClick={handleChecklistItems}
        />
        <SegmentList
          title="Fase Intermediária"
          items={mediumSegments}
          include
          wrapperSize={'90%'}
          onClick={handleChecklistItems}
        />
        <SegmentList
          title="Fase Final"
          items={finalSegments}
          include
          wrapperSize={'90%'}
          onClick={handleChecklistItems}
        />
      </>
    );
  };

  const components = [
    <Checklist
      key={0}
      mode="all"
      segments={selectedSegments || null}
      updatePage={updatePage}
    />,
    <Checklist
      key={1}
      mode="vdb"
      segments={selectedSegments || null}
      updatePage={updatePage}
    />,
    <Checklist
      key={2}
      mode="outside"
      segments={selectedSegments || null}
      updatePage={updatePage}
    />,
  ];

  if (eventFinished) {
    return <Redirect to="/app/noivos/inicio" />;
  }

  let finishedSegments = [];
  if (selectedSegments) {
    finishedSegments = selectedSegments.filter(item => item.finalizado === 1);
  }

  return (
    <PageWrapper>
      <PageTitle>ORÇAMENTO(S) RECEBIDO(s)</PageTitle>
      {selectedSegments && eventFinished === false ? (
        <>
          <Tabs
            content={tabsContent}
            onClick={() => {}}
            components={components}
          />
          <BottomWrapper>
            <DataBalance
              numberOfSegments={selectedSegments && finishedSegments.length}
              value={calculateTotal()}
            />
            <AssetContainer bottom />
          </BottomWrapper>
          <SegmentsTitle>Adicionar segmentos à lista</SegmentsTitle>
          {filtered && renderSegments()}
        </>
      ) : (
        <Loader />
      )}
    </PageWrapper>
  );
};

export default ChecklistPage;
