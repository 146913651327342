import React, { useState } from 'react';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import moment from 'moment';

import '../components/Calendar.css';
import {
  SupplierCalendarCard,
  Tabs,
  Loader,
  CalendarReports,
} from '../components';
import SupplierBridesCard from '../components/SupplierBrideCard';
import useFetch, { method } from '../hooks/useFetch';
// import { medias } from '../utils/mediaQueries';
import theme from '../styles/theme';
import { PageWrapper, PageTitle } from '../components/pageComponents';
import { urls } from '../services/urls';
import { supplier, supplierContent } from '../services/api';
import { toast } from 'react-toastify';
import CardContainer from '../components/CardContainer';

const Wrapper = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
`;

const StyledTabs = styled(Tabs)`
  width: 100%;
`;

const ConfirmedLine = styled.div`
  background-color: green;
  height: 3px;
  width: 50%;
  ${({ half }) => !half && 'margin-left: 25%;'}
`;

const OpportunityLine = styled.div`
  background-color: ${theme.colors.primary};
  height: 3px;
  width: 50%;
  ${({ half }) => !half && 'margin-left: 25%;'}
`;

const LineView = styled.div`
  width: 50%;
  height: 3px;
  display: flex;
  margin-left: 25%;
`;

const SupplierCalendarPage = () => {
  const currentMonth = Number(moment().format('MM')) - 1; // isso traz o mês passado e nao atual ???

  const [budgetData, setbudgetData] = useState(null);
  const [opportunitiesData, setOpportunitiesData] = useState(null);
  const [dayEvents, setDayEvents] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [bridesLoading, setBridesLoading] = useState(false);
  const [isTabVisible, setIsTabVisible] = useState(false);

  const tabsContent = [
    {
      id: 0,
      title: 'ORÇAMENTOS',
    },
    {
      id: 1,
      title: 'CONFIRMADOS',
    },
    {
      id: 2,
      title: 'NOIVOS DISPONÍVEIS',
      visible: isTabVisible,
    },
  ];

  useFetch({
    url: urls.supplierContent.getSupplierEvents(currentMonth),
    method: method.GET,
    condition: () => !budgetData,
    callback: data => {
      setbudgetData(data);
    },
  });

  useFetch({
    url: urls.supplierContent.getOpportunitiesFilteredBrides(currentMonth),
    method: method.GET,
    condition: () => !opportunitiesData,
    callback: data => {
      setOpportunitiesData(data);
    },
  });

  const handleClickDay = async value => {
    fetchBridesByDay(value);
  };

  const fetchBridesByDay = async (value, pageNumber) => {
    setIsLoading(true);
    setIsTabVisible(true);
    setBridesLoading(true);
    const response = await supplierContent.getBridesByDay(
      moment(value).format('YYYY-MM-DD'),
      pageNumber
    );

    if (response.data && response.ok) {
      setDayEvents(response.data.eligibleEvents);
      setBridesLoading(false);
    }

    const response2 = await supplier.getSupplierEvents(null,moment(value).format('YYYY-MM-DD'));
    const response3 = await supplier.getOpportunitiesFilteredBrides(
      null, moment(value).format('YYYY-MM-DD')
    );

    if (response2.data && response2.ok && response3.data && response3.ok) {
      setbudgetData(response2.data);
      setOpportunitiesData(response3.data);
      setIsLoading(false);
    }
  };

  const updateMonthData = async activeStartDate => {
    setIsLoading(true);
    setIsTabVisible(false);
    const activeMonth = Number(moment(activeStartDate).format('MM')) - 1;

    setIsLoading(true);
    const response = await supplier.getSupplierEvents(activeMonth);
    const response2 = await supplier.getOpportunitiesFilteredBrides(
      activeMonth
    );

    if (response.ok && response.data && response2.ok && response2.data) {
      setbudgetData(response.data);
      setOpportunitiesData(response2.data);
      setIsLoading(false);
    }
  };

  const markAsContacted = async id => {
    if (id !== undefined && id !== null) {
      const response = await supplierContent.markBrideAsContacted(id);
      response.ok
        ? toast.success(`Sucesso ao adicionar à Contatados`)
        : toast.error('Erro ao salvar!');
    }
  };

  const ConfirmedCards = () =>
    budgetData &&
    (budgetData.filteredEvents.length > 0 ? (
      budgetData.filteredEvents.map((item, index) => (
        <SupplierCalendarCard key={index} data={item} />
      ))
    ) : (
      <p style={{ textAlign: 'center' }}>Nenhum evento confirmado.</p>
    ));

  const OpportunityCards = () =>
    opportunitiesData &&
    (opportunitiesData.filteredEvents.length > 0 ? (
      opportunitiesData.filteredEvents.map((item, index) => (
        <SupplierCalendarCard key={index} data={item} />
      ))
    ) : (
      <p style={{ textAlign: 'center' }}>Nenhuma oportunidade disponivel.</p>
    ));

  const RenderAvailable = () =>
    dayEvents &&
    (dayEvents.length > 0 ? (
      dayEvents.map(event => (
        <SupplierBridesCard
          key={event.id}
          cardData={event}
          type={0}
          MarkClick={() => markAsContacted(event.id)}
          contract={false}
        />
      ))
    ) : (
      <p style={{ textAlign: 'center' }}>Nenhum noivo(a) disponível.</p>
    ));

  const components = budgetData &&
    opportunitiesData && [
      <OpportunityCards key={0} />,
      <ConfirmedCards key={1} />,
      <>
        {bridesLoading && <Loader />}
        {!bridesLoading && (
          <CardContainer key={2}>
            <RenderAvailable />
          </CardContainer>
        )}
      </>,
    ];

  const renderCalendarLines = (date, view) => {
    if (view === 'month') {
      const hasConfirmed = budgetData.eventsData.find(
        ({ date: eventDate }) => {
          return (
            moment(eventDate).format('YYYY-MM-DD') ===
            moment(date).format('YYYY-MM-DD')
          );
        }
      );

      const hasOpportunity = opportunitiesData.eventsData.find(
        ({ date: eventDate }) => {
          return (
            moment(eventDate).format('YYYY-MM-DD') ===
            moment(date).format('YYYY-MM-DD')
          );
        }
      );

      if (hasConfirmed && !hasOpportunity) {
        return <ConfirmedLine />;
      }

      if (!hasConfirmed && hasOpportunity) {
        return <OpportunityLine />;
      }

      if (hasConfirmed && hasOpportunity) {
        return (
          <LineView>
            <ConfirmedLine half />
            <OpportunityLine half />
          </LineView>
        );
      }
    }
  };

  return (
    <PageWrapper>
      <PageTitle>AGENDA</PageTitle>
      {budgetData && opportunitiesData ? (
        <>
          <Wrapper>
            <Calendar
              onClickDay={value => handleClickDay(value)}
              onActiveDateChange={({ activeStartDate }) =>
                updateMonthData(activeStartDate)
              }
              onClickMonth={value => updateMonthData(value)}
              calendarType="US"
              className="custom-calendar"
              tileContent={({ date, view }) => renderCalendarLines(date, view)}
            />
          </Wrapper>

          {isLoading ? (
            <Loader />
          ) : (
            <>
              <StyledTabs
                content={tabsContent}
                components={components}
                lineWidth={'100%'}
              />
              <CalendarReports
                total={budgetData.totalEvents}
                done={budgetData.doneEvents}
                confirmed={budgetData.confirmedEvents}
              />
            </>
          )}
        </>
      ) : (
        <Loader />
      )}
    </PageWrapper>
  );
};

export default SupplierCalendarPage;
