import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import {
  Tabs,
  Loader,
  SupplierContractForm,
  ContractsReports,
  CardContainer,
  SupplierContractCard,
  SearchBar,
  ContractsFilterModal,
} from '../components';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { PageTitle, PageWrapper } from '../components/pageComponents';
import { supplier } from '../services/api';
import { toast } from 'react-toastify';
import { navigate } from '@reach/router';

const tabsContent = [
  {
    id: 0,
    title: 'NÃO CONFIRMADOS',
  },
  {
    id: 1,
    title: 'CONFIRMADOS',
  },
  {
    id: 2,
    title: 'FINALIZADOS',
  },
];

const Subtitle = styled.h3`
  margin: 1em;
  text-align: center;
`;

const StyledButton = styled.button`
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  font-weight: 400;
  font-size: 0.8rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: none;
  overflow: visible;
  cursor: pointer;
  margin-right: 1rem;
  margin-bottom: 1rem;

  :hover {
    background-color: #202327;
  }
`;

const SupplierContractsPage = () => {
  const [unconfirmedContracts, setUnconfirmedContracts] = useState(null);
  const [confirmedContracts, setConfirmedContracts] = useState(null);
  const [finalizedContracts, setFinalizedContracts] = useState(null);
  const [contractConsolidationData, setContractConsolidationData] = useState(
    null
  );
  const [loading, setLoading] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [filter, setFilter] = useState('year');
  const [filterData, setFilterData] = useState(moment().year());

  const { doUpdate: doUpdateUnconfirmed } = useFetch({
    url: urls.supplierContent.contracts(filter, 'unconfirmed', filterData),
    method: method.GET,
    condition: () => !unconfirmedContracts,
    callback: data => setUnconfirmedContracts(data),
  });

  const { doUpdate: doUpdateConfirmed } = useFetch({
    url: urls.supplierContent.contracts(filter, 'confirmed', filterData),
    method: method.GET,
    condition: () => !confirmedContracts,
    callback: data => setConfirmedContracts(data),
  });

  useFetch({
    url: urls.supplierContent.contracts(filter, 'finalized', filterData),
    method: method.GET,
    condition: () => !finalizedContracts,
    callback: data => setFinalizedContracts(data),
  });

  const { doUpdate: updateConsolidation } = useFetch({
    url: urls.supplierContent.contractsDataConsolidation(),
    method: method.GET,
    condition: () => !contractConsolidationData,
    callback: data => setContractConsolidationData(data),
  });

  // const changeYear = async (inputYear) => {
  //   await setYear(inputYear);
  //   setConfirmedContracts(null);
  //   updateConfirmed();
  //   updateUnconfirmed();
  //   updateConsolidation();
  // }

  const updateConfirmed = () => {
    setConfirmedContracts(null);
    doUpdateConfirmed();
    setContractConsolidationData(null);
    updateConsolidation();
  };

  const updateUnconfirmed = () => {
    setUnconfirmedContracts(null);
    doUpdateUnconfirmed();
    setContractConsolidationData(null);
    updateConsolidation();
  };

  const handleReportUnrecognized = async (id, contractValue) => {
    setLoading(true);
    const response = await supplier.contestContract(id);
    if (response.ok) {
      toast.success('Contestação registrada!');
      const newUnconfirmed = unconfirmedContracts.filter(
        ({ codContract }) => codContract !== id
      );
      setUnconfirmedContracts(newUnconfirmed);
      setContractConsolidationData({
        ...contractConsolidationData,
        unconfirmed: contractConsolidationData.unconfirmed - contractValue,
        total: contractConsolidationData.total - contractValue,
      });
    } else {
      toast.error('Erro ao contestar contrato.');
    }
    setLoading(false);
    updateUnconfirmed();
  };

  const handleConfirmContract = async (id, contractValue) => {
    const confirmPayload = {
      codContract: id,
      contractValue,
    };
    setLoading(true);
    const response = await supplier.confirmContract(confirmPayload);
    if (response.ok) {
      toast.success('Contrato confirmado!');
      updateUnconfirmed();
      updateConfirmed();
    } else {
      toast.error('Erro confirmar contrato.');
    }
    setLoading(false);
  };

  const openModal = () => {
    setFilterModal(true);
  };

  const handleSearch = input => {
    if (input) {
      setFilter('nameOrEmail');
      setFilterData(input);
      setConfirmedContracts(null);
      updateConfirmed();
      updateUnconfirmed();
      updateConsolidation();
    }
  };

  const handleFilter = async (type, data) => {
    if (type === 'year') {
      setFilter('year');
      setFilterData(data);
    }

    if (type === 'weddingDate') {
      setFilter('weddingDate');
      const formattedDate = {
        from: moment(data.from).format('YYYY-MM-DD'),
        to: moment(data.to).format('YYYY-MM-DD')
      }
      setFilterData(formattedDate);
    }

      setFilterModal(false);
      setConfirmedContracts(null);
      updateConfirmed();
      updateUnconfirmed();
      updateConsolidation();
  };

  const clearFilter = async () => {
    setFilterModal(false);
  };

  const SupplierContracts = (contracts, unconfirmedList) => (
    <CardContainer>
      {contracts && contracts.length > 0 ? (
        contracts.map((contract, index) => (
          <SupplierContractCard
            data={contract}
            key={contract.codContract}
            last={index === contracts.length - 1}
            unconfirmedList={unconfirmedList}
            handleReportUnrecognized={handleReportUnrecognized}
            handleConfirmContract={handleConfirmContract}
            loading={loading}
          />
        ))
      ) : (
        <p style={{ textAlign: 'center' }}>Nenhum contrato disponivel.</p>
      )}
    </CardContainer>
  );

  const components = [
    SupplierContracts(unconfirmedContracts, true),
    SupplierContracts(confirmedContracts, false),
    SupplierContracts(finalizedContracts, false),
  ];

  return (
    <PageWrapper>
      <PageTitle>CONTRATOS</PageTitle>
      <ContractsFilterModal
        onClickFilter={handleFilter}
        onClickClear={clearFilter}
        opened={filterModal}
        closeModal={() => setFilterModal(false)}
      />
      <StyledButton onClick={() => navigate(`noivos`)}>
        Clique aqui para pontuar noivos
      </StyledButton>
      <SearchBar
        filter
        placeholder="Nome ou e-mail do noivo"
        onClickFilter={openModal}
        onClickSearch={input => handleSearch(input)}
      />

      {unconfirmedContracts &&
      confirmedContracts &&
      finalizedContracts &&
      contractConsolidationData ? (
        <>
          <Tabs
            content={tabsContent}
            components={components}
            onClick={() => {}}
          />
          <ContractsReports data={contractConsolidationData || null} />
          <Subtitle>Cadastrar contrato</Subtitle>
          <SupplierContractForm />
        </>
      ) : (
        <Loader />
      )}
    </PageWrapper>
  );
};

export default SupplierContractsPage;
