import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { supplier } from '../services/api';
import { Button } from './';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  h3 {
    margin: 0;
    font-size: 1em;
  }
  b {
    color: ${theme.colors.primary};
  }
  img {
    flex-basis: 100%;
    margin: 0 0 1rem 0;
    max-height: 200px;
    object-fit: cover;
    border-radius: 1em;
    box-shadow: 0 2px 4px ${theme.colors.grey3};
  }
  @media ${medias.tabletMin} {
    img {
      flex: 0 1 45%;
      margin: 0 1rem 0 0;
    }
  }
`;

const RowContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: ${({ first }) => !first && 'center'};
  padding-bottom: 1em;
  ${({ border }) =>
    border &&
    `
    border-bottom: 1px solid ${theme.colors.grey1}
    margin-bottom: 0.7em;
  `}
`;

const HalfButton = styled(props => <Button {...props} />)`
  @media ${medias.phoneMax} {
    height: 40px;
    font-size: ${theme.fontSizeSm}px;
  }
  background-color: ${theme.colors.text_heading};
  border: 2px solid ${theme.colors.text_heading};
  color: ${theme.colors.white};
  width: 90%;
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
`;

const SupplierRewardCard = ({
  VB$,
  codReward,
  description,
  image,
  name,
  balance = 300,
  redeemed,
}) => {
  const [isLoading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(redeemed > 0);
  const [textWidth, setTextWidth] = React.useState({width: '50%'});
  const imgRef = React.useRef(null);

  // Log para evitar erro de linter
  console.log(success);

  const canRedeem = VB$ <= balance;

  const redeem = async () => {
    if (!canRedeem) {
      return;
    }
    setLoading(true);
    const res = await supplier.redeemReward(codReward);
    if (res.ok) {
      setSuccess(true);
      window.location.reload();
    }
    setLoading(false);
  };

  // Se a imagem não carregar, queremos escondê-la
  const hideBrokenImage = () => {
    imgRef.current.style.display = 'none';
    setTextWidth({width: '100%'})
  };

  return (
    <Wrapper>
      <img
        ref={imgRef}
        onError={hideBrokenImage}
        src={image}
        alt={`Foto em destaque para ${name}`}
      />
      <div style={textWidth}>
        <h3>{name}</h3>
        {description && <p>{description}</p>}
        <p>
          Valor: <b>{VB$}</b> moedas
        </p>
      </div>
      <RowContainer>
        <HalfButton 
        disabled={isLoading || !canRedeem}
        text={!canRedeem ? 'SALDO INSUFICIENTE' : (isLoading ? 'RESGATANDO...' : 'RESGATAR')}

        click={redeem} />
      </RowContainer>
    </Wrapper>
  );
};

SupplierRewardCard.propTypes = {
  VB$: PropTypes.number.isRequired,
  codReward: PropTypes.number.isRequired,
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  balance: PropTypes.number.isRequired,
  redeemed: PropTypes.number.isRequired,
  image: PropTypes.string,
};

export default SupplierRewardCard;
