import React, { useState } from 'react';
import styled from 'styled-components';

import {
  SupplierPrizeCard,
  SupplierStockedPrizesCard,
  Tabs,
  Loader,
  PrizeDataHistory,
} from '../components';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { PageWrapper, PageTitle } from '../components/pageComponents';

const tabsContent = [
  {
    id: 0,
    title: 'RESGATADOS',
  },
  {
    id: 1,
    title: 'EM ESTOQUE',
  },
];

const StyledTabs = styled(Tabs)`
  width: 100%;
`;

const SupplierRewardsPage = () => {
  const [redeemedData, setRedeemedData] = useState(null);
  const [stockedData, setStockedData] = useState(null);
  const [prizeConsolidationData, setPrizeConsolidationData] = useState(null);

  const { doUpdate: updateRedeemed } = useFetch({
    url: urls.supplierContent.redeemedPrizes(),
    method: method.GET,
    condition: () => !redeemedData,
    callback: data => setRedeemedData(data),
  });

  const { doUpdate: updateStocked } = useFetch({
    url: urls.supplierContent.stockedPrizes(),
    method: method.GET,
    condition: () => !stockedData,
    callback: data => setStockedData(data),
  });

  const { doUpdate: updatePrizeConsolidation } = useFetch({
    url: urls.supplierContent.prizeDataConsolidation(),
    method: method.GET,
    condition: () => !prizeConsolidationData,
    callback: data => setPrizeConsolidationData(data),
  });

  const refetchData = async () => {
    setRedeemedData(null);
    setStockedData(null);
    setPrizeConsolidationData(null);
    await updateRedeemed();
    await updateStocked();
    await updatePrizeConsolidation();
  };

  let prizeReportData = {
    redeemed: 0,
    stock: 0,
  };

  if (redeemedData && stockedData) {
    const stock = stockedData.reduce((acc, { stock }) => (acc += stock), 0);
    prizeReportData = {
      ...prizeReportData,
      redeemed: redeemedData.length,
      stock: stock,
    };
  }

  const components = [
    <SupplierPrizeCard
      key={0}
      data={redeemedData}
      reportData={prizeReportData}
      refetchData={refetchData}
    />,
    <SupplierStockedPrizesCard
      key={1}
      data={stockedData}
      reportData={prizeReportData}
      refetchData={refetchData}
    />,
  ];

  return (
    <PageWrapper>
      <PageTitle>RECOMPENSAS</PageTitle>
      {redeemedData && stockedData && prizeConsolidationData ? (
        <>
          <StyledTabs
            content={tabsContent}
            components={components}
            lineWidth={'100%'}
            onClick={() => {}}
          />
          <PrizeDataHistory data={prizeConsolidationData} />
        </>
      ) : (
        <Loader />
      )}
    </PageWrapper>
  );
};

export default SupplierRewardsPage;
