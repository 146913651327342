import React, { useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
  PageTitle,
  PageWrapper,
  CenteredText,
} from '../components/pageComponents';
import CardContainer from '../components/CardContainer';
import {
  Tabs,
  Loader,
  Pagination,
  SearchBar,
  BrideFilterModal,
  Pagination2
} from '../components';
import theme from '../styles/theme';
import { supplierContent, supplier } from '../services/api';
import SupplierBridesCard from '../components/SupplierBrideCard';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { scrollToRef } from '../hooks';
import { toast } from 'react-toastify';
const tabsContent = [
  {
    id: 0,
    title: 'DISPONÍVEIS',
  },
  {
    id: 1,
    title: 'CONVIDADOS',
  },
  {
    id: 2,
    title: 'CONTATADOS',
  },
  {
    id: 3,
    title: 'JÁ CASADOS (pontuar)'
  },
];

const SumContainer = styled.section`
  width: 90%;
  margin-top: 10px;
  background: ${theme.colors.text_heading};
  color: white;
  text-align: center;
  padding: 1.5rem 0.5rem;
  b,
  h2 {
    color: ${theme.colors.primary};
  }
  h2 {
    margin: 1rem 0;
  }
  hr {
    background: white;
    opacity: 0.5;
    width: 70%;
    margin: 0.5rem auto;
  }
`;

const StyledTabs = styled(props => <Tabs {...props} />)`
  margin-top: 1em;
`;

const StyledDiv = styled.div`
  width:100%;
`;

const SupplierBridesPage = () => {
  const [initialPage, setInitialPage] = React.useState(1);
  const [order, setOrder] = React.useState('eventDate');

  const [filter, setFilter] = React.useState(null);
  const [segmentFilter, setSegmentFilter] = React.useState(null);
  const [regionFilter, setRegionFilter] = React.useState(null);
  const [weddingDateFilter, setWeddingDateFilter] = React.useState(null);
  const [brideNameEmailFilter, setBrideNameEmailFilter] = React.useState(null);
  const [registerFilter, setRegisterFilter] = React.useState(null);
  const [receptionFilter, setReceptionFilter] = React.useState(null);
  const [invitedFilter, setInvitedFilter] = React.useState(null);
  const [supplierFilter, setSupplierFilter] = React.useState(null);

  const [avaliableBrides, setAvaliableBrides] = React.useState(null);
  const [invitedBrides, setInvitedBrides] = React.useState(null);
  const [contactedBrides, setContactedBrides] = React.useState(null);
  const [marriedBrides, setMarriedBrides] = React.useState(null);
  const [reportAvaliableData, setReportAvaliableData] = React.useState(null);
  const [reportInvitedData, setReportInvitedData] = React.useState(null);
  const [reportContactedData, setReportContactedData] = React.useState(null);
  const [reportMarriedData, setReportMarriedData] = React.useState(null);

  const [activeTab, setActiveTab] = React.useState(0);
  const [searched, setSearched] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [filterModal, setFilterModal] = React.useState(false);
  const [regionData, setRegionData] = React.useState(false);
  const [segmentData, setSegmentsData] = React.useState(false);
  const [otherSuppliers, setOtherSuppliers] = React.useState(false);

  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  const resPerPage = 10;
  const monthAgo = moment().subtract(30, 'days').format('YYYY-MM-DD');
  const today = moment().format('YYYY-MM-DD');

  const fetchSupplierBrides = async (pageNumber) => {
    setInitialPage(pageNumber);
    setIsLoading(true);

    if (activeTab === 0) {
      if (!filter) {
        const response = await supplier.getBridesFilteredByType(order, 'potenciais', pageNumber, resPerPage);

        if (response.ok && response.data) {
          changeState(response.data);
          setIsLoading(false)
          return;
        }
      }


      if (filter === 'segment') {
        const res = await supplierContent.getBridesBySegment(order, 'potenciais', segmentFilter, pageNumber, resPerPage);

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'region') {
        const res = await supplierContent.getBridesByRegion(order, 'potenciais', regionFilter, pageNumber, resPerPage);

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'weddingDate') {
        const initialDate = moment(weddingDateFilter.from).format('YYYY-MM-DD');
        const finalDate = moment(weddingDateFilter.to).format('YYYY-MM-DD');
        let tp = 'potenciais';
        if (initialDate < today) {
          tp = 'casados';
        }
        const res = await supplierContent.getBridesByDate(order, tp, initialDate, finalDate, pageNumber, resPerPage);

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'bride') {
        const { name, email } = brideNameEmailFilter;
        const res = await supplierContent.getBridesByBrides(order, 'potenciais', name, email, pageNumber, resPerPage);

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'register') {
        const initialDate = moment(registerFilter.from).format('YYYY-MM-DD');
        const finalDate = moment(registerFilter.to).format('YYYY-MM-DD');
        const res = await supplierContent.getBridesByRegister(order, 'potenciais', initialDate, finalDate, pageNumber, resPerPage);

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'reception') {
        const res = await supplierContent.getBridesByReception(order, 'potenciais', receptionFilter, pageNumber, resPerPage);

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }


      if (filter === 'invited') {
        const res = await supplierContent.getBridesByInvitation(order, 'potenciais', invitedFilter, pageNumber, resPerPage);

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'supplier') {
        const res = await supplierContent.getBridesBySupplier(order, supplierFilter, pageNumber, resPerPage);

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }
    }

    if (activeTab === 1) {
      if (!filter) {
        const response = await supplier.getBridesFilteredByType(order, 'convidados', pageNumber, resPerPage);

        if (response.ok && response.data) {
          changeState(null, response.data);
          setActiveTab(activeTab)
          setIsLoading(false)
          return;
        }
      }

      if (filter === 'region') {
        const res = await supplierContent.getBridesByRegion(order, 'convidados', regionFilter, pageNumber, resPerPage);

        if (res.ok && res.data) {
          changeState(null, res.data);
          setFilterModal(false);
          setActiveTab(activeTab)
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'weddingDate') {
        const initialDate = moment(weddingDateFilter.from).format('YYYY-MM-DD');
        const finalDate = moment(weddingDateFilter.to).format('YYYY-MM-DD');
        let res;
        let tp = 'convidados';
        if (initialDate < today) {
          tp = 'casados';
          res = await supplierContent.getBridesByDate(order, tp, initialDate, finalDate, pageNumber, resPerPage);

          if (res.ok && res.data) {
            changeState(null, null, null, res.data);
            setFilterModal(false);
            setActiveTab(3)
            setIsLoading(false);
            setSearched(true);
            return;
          }
        }
        res = await supplierContent.getBridesByDate(order, tp, initialDate, finalDate, pageNumber, resPerPage);

        if (res.ok && res.data) {
          changeState(null, res.data);
          setFilterModal(false);
          setActiveTab(activeTab)
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'bride') {
        const { name, email } = brideNameEmailFilter;
        const res = await supplierContent.getBridesByBrides(order, 'convidados', name, email, pageNumber, resPerPage);

        if (res.ok && res.data) {
          changeState(null, res.data);
          setFilterModal(false);
          setActiveTab(activeTab)
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'register') {
        const initialDate = moment(registerFilter.from).format('YYYY-MM-DD');
        const finalDate = moment(registerFilter.to).format('YYYY-MM-DD');
        const res = await supplierContent.getBridesByRegister(order, 'convidados', initialDate, finalDate, pageNumber, resPerPage);

        if (res.ok && res.data) {
          changeState(null, res.data);
          setFilterModal(false);
          setActiveTab(activeTab)
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'reception') {
        const res = await supplierContent.getBridesByReception(order, 'convidados', receptionFilter, pageNumber, resPerPage);

        if (res.ok && res.data) {
          changeState(null, res.data);
          setFilterModal(false);
          setActiveTab(activeTab)
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }
    }
    if (activeTab === 2) {
      const response = await supplierContent.getContactedBrides(pageNumber, resPerPage);

      if (response.ok && response.data) {
        changeState(null, null, response.data);
        setActiveTab(activeTab);
        setIsLoading(false);
        return;
      }
    }
    if (activeTab === 3) {
      setIsLoading(true);
      const response = await supplierContent.getBridesByDate(order,
        'casados',
        monthAgo,
        today, pageNumber, resPerPage
      );
      if (response.ok && response.data) {
        changeState(null, null, null, response.data);
        setActiveTab(activeTab);
        setIsLoading(false);
        return;
      }
    }
  };

  const { doUpdate } = useFetch({
    url: urls.supplierContent.getBridesFilteredByType(order, 'potenciais', 1, resPerPage),
    method: method.GET,
    condition: () => !avaliableBrides,
    callback: data => changeState(data),
  });

  const { doUpdate: doUpdateInvited } = useFetch({
    url: urls.supplierContent.getBridesFilteredByType(order, 'convidados', 1, resPerPage),
    method: method.GET,
    condition: () => !invitedBrides,
    callback: data => changeState(null, data),
  });

  const { doUpdate: doUpdateMarried } = useFetch({
    url: urls.supplierContent.getBridesFilteredByType(order, 'casados', 1, resPerPage),
    method: method.GET,
    condition: () => !marriedBrides,
    callback: data => changeState(null, null, null, data),
  });

  useFetch({
    url: urls.supplierContent.supplierRegions,
    method: method.GET,
    condition: () => !regionData,
    callback: data => setRegionData(data),
  });

  useFetch({
    url: urls.supplierContent.getOtherSuppliers,
    method: method.GET,
    condition: () => !otherSuppliers,
    callback: data => setOtherSuppliers(data),
  });

  useFetch({
    url: urls.supplierContent.supplierSegments,
    method: method.GET,
    condition: () => !regionData,
    callback: data => setSegmentsData(data),
  });

  useFetch({
    url: urls.supplierContent.getContactedBrides,
    method: method.GET,
    condition: () => !contactedBrides,
    callback: data => {
      if (data !== undefined && data !== null) {
        const { eligibleEvents, count } = data;
        setContactedBrides(eligibleEvents);
        setReportContactedData(count);
        console.log("Data retrived from useFetch ~ getContactedBrides ::");
        console.dir(data); // will this make optimistic update to display the new list after marking?
      } // or should I add a name to it and call it inside onTabChange function!?
    },
  });
  // Shall I use useFetch ? if yes, how?

  const changeState = (available, invited, contacted, married) => {
    if (available) {
      setAvaliableBrides(
        available !== undefined ? available.eligibleEvents : undefined
      );
      setReportAvaliableData(
        available !== undefined ? available.count : undefined
      );
    }
    if (invited) {
      setInvitedBrides(
        invited !== undefined ? invited.eligibleEvents : undefined
      );
      setReportInvitedData(invited !== undefined ? invited.count : undefined);
    }
    if (contacted) {
      setContactedBrides(
        contacted !== undefined ? contacted.eligibleEvents : undefined
      );
      setReportContactedData(contacted !== undefined ? contacted.count : undefined);
    }
    if (married) {
      setMarriedBrides(
        married !== undefined ? married.eligibleEvents : undefined
      );
      setReportMarriedData(married !== undefined ? married.count : undefined);
    }
  };

  const updateOportunities = () => {
    setAvaliableBrides(null);
    doUpdate();
  };

  const onPageChange = (pageNumber) => {
    setIsLoading(true)
    fetchSupplierBrides(pageNumber);
  };

  const markAsContacted = async (id) => {
    if (id !== undefined && id !== null) {
      const response = await supplierContent.markBrideAsContacted(id);
      response.ok ? (toast.success(`Sucesso ao adicionar à Contatados`)) : (toast.error('Erro ao salvar!'));
    }
  };

  const unmarkAsContacted = async (id) => {
    if (id !== undefined && id !== null) {
      const response = await supplierContent.unmarkBrideAsContacted(id);
      response.ok ? (toast.success(`Sucesso ao remover de Contatados`)) : (toast.error('Erro ao remover!'));
    }
  }

  const renderAvaliable = bride => (
    <SupplierBridesCard
      key={bride.id}
      cardData={bride}
      type={0}
      segmentData={segmentData}
      regionsData={regionData}
      updateOportunities={updateOportunities}
      MarkClick={() => markAsContacted(bride.id)}
    />
  );

  const renderBrides = bride => (
    <SupplierBridesCard key={bride.id} cardData={bride} type={1} />
  );

  const renderContacted = bride => (
    <SupplierBridesCard
      key={bride.id}
      cardData={bride}
      type={2}
      segmentData={segmentData}
      regionsData={regionData}
      updateOportunities={updateOportunities}
      UnmarkClick={() => unmarkAsContacted(bride.id)}
    />
  );

  const renderMarried = bride => (
    <SupplierBridesCard
      key={bride.id}
      cardData={bride}
      type={3}
      segmentData={segmentData}
      regionsData={regionData}
      updateOportunities={updateOportunities}
    />
  );

  const CardComponent = ({ data, type }) =>
    data && data.length > 0 && data !== undefined ? (
      type === 0 ? (
        <Pagination
          itemsPerPage={10}
          data={data}
          renderItem={renderAvaliable}
          showButtons={false}
        />
      ) : type === 1 ? (
        <Pagination itemsPerPage={10} data={data} renderItem={renderBrides} showButtons={false} />
      ) : type === 2 ? (
        <Pagination itemsPerPage={30} data={data} renderItem={renderContacted} showButtons={false} />
      ) : type === 3 ? (
        <Pagination itemsPerPage={30} data={data} renderItem={renderMarried} showButtons={false} />
      ) : ''
    ) : (
        <CenteredText>Nenhum noivo disponivel!</CenteredText>
      );

  const components = [
    <CardContainer key={0}>
      <CardComponent data={avaliableBrides} type={0} />
    </CardContainer>
    ,
    <CardContainer key={1}>
      <CardComponent data={invitedBrides} type={1} />
    </CardContainer>,
    <CardContainer key={2}>
      <CardComponent data={contactedBrides} type={2} />
    </CardContainer>,
    <CardContainer key={3}>
      <CardComponent data={marriedBrides} type={3} />
    </CardContainer>,
  ];

  const openModal = () => {
    setFilterModal(true);
  };

  const handleTabChange = tab => {
    setActiveTab(tab);
    setInitialPage(1);
  };

  const doEmptySearch = async () => {
    const response = await supplier.getBridesFilteredByType(order, 'potenciais', 1, resPerPage);
    const response2 = await supplier.getBridesFilteredByType(order, 'convidados', 1, resPerPage);

    if (response.ok && response.data && response2.ok && response2.data) {
      changeState(response.data, response2.data);
      setIsLoading(false)
      return;
    }
  }

  const handleSearch = async input => {
    setInitialPage(1)
    setAvaliableBrides(null)
    setReportAvaliableData(null)
    setInvitedBrides(null)
    setReportInvitedData(null)
    setIsLoading(true);

    let response, response2;

    if (!input) {
      doEmptySearch();
      return;
    }

    if (input.includes('@')) {
      response = await supplierContent.getBridesByBrides(order, 'convidados', null, input);
      response2 = await supplierContent.getBridesByBrides(order, 'potenciais', null, input);
    }
    else {
      response = await supplierContent.getBridesByName(order, 'convidados', input);
      response2 = await supplierContent.getBridesByName(
        order,
        'potenciais',
        input
      );
    }

    if (response.ok && response.data && response2.ok && response2.data) {
      changeState(response2.data, response.data);
      setFilterModal(false);
      setIsLoading(false);
      setSearched(true);
      return;
    }
  };

  const handleChangeOrder = async (value) => {
    setOrder(value);
  }

  const handleClear = async () => {
    setAvaliableBrides(null)
    setReportAvaliableData(null)
    setInvitedBrides(null)
    setReportInvitedData(null)
    setIsLoading(true);
    setInitialPage(1);

    const response = await supplierContent.getBridesFilteredByType(order,
      'convidados'
    );
    const response2 = await supplierContent.getBridesFilteredByType(order,
      'potenciais'
    );

    if (response.ok && response.data && response2.ok && response2.data) {
      changeState(response2.data, response.data);
      setSearched(false);
      setIsLoading(false);
      return;
    }
  };

  const handleFilter = async (type, data) => {
    setFilter(type)
    setInitialPage(1)
    setAvaliableBrides(null)
    setReportAvaliableData(null)
    setInvitedBrides(null)
    setReportInvitedData(null)

    if (type === 'segment') {
      setSegmentFilter(data)
      setIsLoading(true);
      const res = await supplierContent.getBridesBySegment(order, 'potenciais', data);

      if (res.ok) {
        changeState(res.data);
        setFilterModal(false);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (type === 'region') {
      setRegionFilter(data)
      setIsLoading(true);
      const response = await supplierContent.getBridesByRegion(order,
        'convidados',
        data
      );
      const response2 = await supplierContent.getBridesByRegion(order,
        'potenciais',
        data
      );

      if (response.ok && response.data && response2.ok && response2.data) {
        changeState(response2.data, response.data);
        setFilterModal(false);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (type === 'weddingDate') {
      setWeddingDateFilter(data)
      setIsLoading(true);
      const initialDate = moment(data.from).format('YYYY-MM-DD');
      const finalDate = moment(data.to).format('YYYY-MM-DD');
      const today = moment().format('YYYY-MM-DD');

      if (initialDate < today) {
        const response = await supplierContent.getBridesByDate(order,
          'casados',
          initialDate,
          finalDate
        );

        if (response.ok && response.data) {
          changeState(null, null, null, response.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }
      const response = await supplierContent.getBridesByDate(order,
        'convidados',
        initialDate,
        finalDate
      );

      const response2 = await supplierContent.getBridesByDate(order,
        'potenciais',
        initialDate,
        finalDate
      );

      if (response.ok && response.data && response2.ok && response2.data) {
        changeState(response2.data, response.data);
        setFilterModal(false);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (type === 'bride') {
      setBrideNameEmailFilter(data)
      setIsLoading(true);
      const { name, email } = data;

      const response = await supplierContent.getBridesByBrides(order,
        'convidados',
        name,
        email
      );

      const response2 = await supplierContent.getBridesByBrides(order,
        'potenciais',
        name,
        email
      );

      if (response.ok && response.data && response2.ok && response2.data) {
        changeState(response2.data, response.data);
        setFilterModal(false);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (type === 'register') {
      setRegisterFilter(data)
      setIsLoading(true);
      const initialDate = moment(data.from).format('YYYY-MM-DD');
      const finalDate = moment(data.to).format('YYYY-MM-DD');
      const response = await supplierContent.getBridesByRegister(order,
        'convidados',
        initialDate,
        finalDate
      );

      const response2 = await supplierContent.getBridesByRegister(order,
        'potenciais',
        initialDate,
        finalDate
      );

      if (response.ok && response.data && response2.ok && response2.data) {
        changeState(response2.data, response.data);
        setFilterModal(false);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (type === 'reception') {
      setReceptionFilter(data)
      setIsLoading(true);
      const response = await supplierContent.getBridesByReception(order,
        'convidados',
        data
      );

      const response2 = await supplierContent.getBridesByReception(order,
        'potenciais',
        data
      );

      if (response.ok && response.data && response2.ok && response2.data) {
        changeState(response2.data, response.data);
        setFilterModal(false);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (type === 'invited') {
      setInvitedFilter(data);
      setIsLoading(true);
      const res = await supplierContent.getBridesByInvitation(order,
        'potenciais',
        data
      );

      if (res.ok) {
        changeState(res.data);
        setFilterModal(false);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (type === 'supplier') {
      setSupplierFilter(data)
      setIsLoading(true);
      const res = await supplierContent.getBridesBySupplier(order, data);

      if (res.ok) {
        changeState(res.data);
        setFilterModal(false);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }
  };

  const clearFilter = async () => {
    setAvaliableBrides(null);
    setReportAvaliableData(null);
    setInvitedBrides(null);
    setReportInvitedData(null);
    setMarriedBrides(null);
    setReportMarriedData(null);
    doUpdate();
    doUpdateInvited();
    doUpdateMarried();
    setFilterModal(false);
  }

  return (
    <PageWrapper>
      <PageTitle>Noivos</PageTitle>
      <BrideFilterModal
        suppliersData={otherSuppliers}
        regionsData={regionData}
        segmentsData={segmentData}
        onClickFilter={handleFilter}
        onClickClear={clearFilter}
        opened={filterModal}
        closeModal={() => setFilterModal(false)}
      />
      <StyledDiv ref={myRef}>
        <SearchBar
          filter
          placeholder="Nome ou email do noivo"
          searched={searched}
          onClickClear={handleClear}
          onClickFilter={openModal}
          onClickSearch={input => handleSearch(input)}
          handleChangeOrder={handleChangeOrder}
        />
      </StyledDiv>

      {avaliableBrides && invitedBrides && marriedBrides && !isLoading ? (
        <>
          <StyledTabs content={tabsContent} components={components} onClick={(activeTab) => handleTabChange(activeTab)} activeTab={activeTab} hasActiveTab={true} />
        </>
      ) : (
          <Loader />
        )}
      {avaliableBrides && avaliableBrides.length > 0 &&
        reportAvaliableData && reportAvaliableData > 0 &&
        activeTab === 0 && (
          <Pagination2 items={avaliableBrides} itemsTotal={reportAvaliableData} onChangePage={onPageChange} pageSize={resPerPage} scrollToRef={executeScroll} initialPage={initialPage} />
        )}
      {invitedBrides && invitedBrides.length > 0 &&
        reportInvitedData && reportInvitedData > 0 &&
        activeTab === 1 && (
          <Pagination2 items={invitedBrides} itemsTotal={reportInvitedData} onChangePage={onPageChange} pageSize={resPerPage} scrollToRef={executeScroll} initialPage={initialPage} />
        )}
      {contactedBrides && contactedBrides.length > 0 &&
        reportContactedData && reportContactedData > 0 &&
        activeTab === 2 && (
          <Pagination2 items={contactedBrides} itemsTotal={reportContactedData} onChangePage={onPageChange} pageSize={resPerPage || 15} scrollToRef={executeScroll} initialPage={initialPage} />
        )}
      {marriedBrides && marriedBrides.length > 0 &&
        reportMarriedData && reportMarriedData > 0 &&
        activeTab === 3 && (
          <Pagination2 items={marriedBrides} itemsTotal={reportMarriedData} onChangePage={onPageChange} pageSize={resPerPage || 15} scrollToRef={executeScroll} initialPage={initialPage || 1} />
        )}
      <SumContainer>
        <p>
          Noivos disponíveis: <b>{reportAvaliableData ? reportAvaliableData : '-'}</b>
        </p>
        <p>
          Noivos convidados: <b>{reportInvitedData ? reportInvitedData : '-'}</b>
        </p>
        <p>
          Noivos contatados: <b>{reportContactedData ? reportContactedData : '-'}</b>
        </p>
        <p>
          Noivos já casados: <b>{reportMarriedData ? reportMarriedData : '-'}</b>
        </p>
        <hr />
        <h2>
          Total: <b>{reportAvaliableData + reportInvitedData + reportContactedData + reportMarriedData}</b>
        </h2>
      </SumContainer>
    </PageWrapper>
  );
};

export default SupplierBridesPage;
